<template>
  <ContentfulRenderer
    class="the-footer-responsible-play text-caption text-center"
    :data="footer?.items[0]?.responsibleGamingSection"
  />
</template>

<script lang="ts" setup>
const footer = useFooter()
</script>

<style lang="scss" scoped>
.the-footer-responsible-play {
  :deep(a) {
    display: inline-flex;
  }

  :deep(p) {
    + p {
      margin-top: spacing('xs');
    }
  }
}
</style>
