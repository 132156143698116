import type { CmsFooterQuery } from '~/@types/generated/cms/graphql-schema-types'

export const useFooter = () => {
  const footer = useState<CmsFooterQuery['footerCollection']>()
  const isLoading = useState(() => false)

  const { getFooter } = useContentfulContent()
  const { log } = useDatadog()

  const loadFooterContent = async () => {
    if (footer.value || isLoading.value) return

    try {
      isLoading.value = true
      const { data } = await getFooter()
      footer.value = data.value
    } catch (error) {
      log('error', {
        component: 'useFooter.ts',
        error,
        message: 'Failed to load footer content',
      })
    } finally {
      isLoading.value = false
    }
  }

  onMounted(loadFooterContent)
  onServerPrefetch(loadFooterContent)

  return footer
}
